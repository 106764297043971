<template>
  <div class="flex flex-col justify-between h-full">
    <div class="m-8">
      <CgBarChart :ref="'chart_' + remCampaignId" :chart-data="chartData" :chart-options="options" class="object-fill"></CgBarChart>
    </div>
    <div>
      <div class="remediated-card-footer">
        <Txt class="text-primary-300">
          <Txt as="span">{{ improved }}</Txt>
          {{ $t('Remediation.Remediated' + type, { param: targets }) }}
        </Txt>
        <div v-if="remediationDetails.includes(type)" class="float-right mt-4">
          <CGButton :ref="'remDetailsBtn_' + remCampaignId" @click="modalKey++; remediationDetailsModal=true">
            {{ $t('Remediation.Details') }}
          </CGButton>
        </div>
      </div>
    </div>

    <!-- Details modal -->
    <Modal
      :key="modalKey"
      ref="remediationDetailsModal"
      v-model:open="remediationDetailsModal"
      :title="$t('Remediation.Details')"
      footer-alignment="center"
      with-close-button
      with-expand-button
      full-height
      full-width
      :trigger="$refs['remDetailsBtn_' + remCampaignId]"
    >
      <Tabs :tab-nav="remediatedLightTabs">
        <template #tabContent-1>
          <CGTable
            ref="rem_targets"
            v-model:sort-key="target.sortKey"
            v-model:sort-direction="target.sortDirection"
            :name="$t('Navbar.Targets')"
            alternate-color="light"
          >
            <template #head>
              <TableCell v-for="el in target.fields" :key="el.key" :sort-key="el.key" head>{{ el.label }}</TableCell>
            </template>

            <TableRow v-for="(t, i) in target.items" :key="'target_entry_'+i" :record="t">
              <TableCell v-for="(el, k) in target.fields" :key="'target_data_'+i+'_'+k">
                {{ t[el.key] }}
              </TableCell>
            </TableRow>

            <TableRow v-if="target.items && !target.items.length" :record="{}">
              <td :colspan="target.fields && target.fields.length">
                <Txt bold class="text-center my-8 mx-auto">{{ $t('Dashboard.Charts.NoData') }}</Txt>
              </td>
            </TableRow>
          </CGTable>

          <Pagination :page="target.page" :items-per-page="target.itemsPerPage" :total-items="targetDataOrigin.length" class="mt-3" @page-change="targetPageChange" />
        </template>

        <template #tabContent-2>
          <CGTable
            ref="rem_template"
            v-model:sort-key="template.sortKey"
            v-model:sort-direction="template.sortDirection"
            :name="$t('Navbar.Template')"
            alternate-color="light"
          >
            <template #head>
              <TableCell v-for="el in template.fields" :key="el.key" :sort-key="el.key" head>{{ el.label }}</TableCell>
            </template>
            <TableRow v-for="(t, i) in template.items" :key="'template_entry_'+i" :record="t">
              <TableCell v-for="(el, k) in template.fields" :key="'template_data_'+i+'_'+k">
                {{ t[el.key] }}
              </TableCell>
            </TableRow>

            <TableRow v-if="template.items && !template.items.length" :record="{}">
              <td :colspan="template.fields && template.fields.length">
                <Txt bold class="text-center my-8 mx-auto">{{ $t('Dashboard.Charts.NoData') }}</Txt>
              </td>
            </TableRow>
          </CGTable>

          <Pagination :page="template.page" :items-per-page="template.itemsPerPage" :total-items="templateDataOrigin.length" class="mt-3" @page-change="templatePageChange" />
        </template>
      </Tabs>

      <template #footer>
        <CGButton v-if="remediationReport.includes(type)" color="primary" @click="downloadReport">
          <Txt weight="bold" small>
            {{ $t('Dashboard.CreateReport') }}
          </Txt>
        </CGButton>
        <CGButton v-if="$checkPermission(['phishing-view-target-risk']) " color="primary" @click="exportReport">
          <Txt weight="bold" small>
            {{ $t('General.Export') }}
          </Txt>
        </CGButton>
        <CGButton color="secondary" @click="remediationDetailsModal=false">
          {{ $t('General.Close') }}
        </CGButton>
      </template>
    </Modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import colors from '@/utils/colors'
import campaignService from '@/services/campaign.service'
import phishingService from '@/services/phishing.service'
import reportService from '@/services/reporting.service'
import { Campaign, TemplateType } from '@/common/constants'

export default defineComponent({
  props: {
    data: Array,
    type: Number,
    remCampaignId: String,
    remLevelId: String,
    companyLan: String,
    companyId: String,
    tags: Object
  },

  data() {
    return {
      sortKey: 'click_rate',
      sortDirection: 'desc',
      modalKey: 0,
      options: {},
      template: {
        sortKey: null,
        sortDirection: null,
        fields: [],
        items: [],
        page: 1,
        itemsPerPage: 15,
        totalItems: 0
      },
      target: {
        sortKey: null,
        sortDirection: null,
        fields: [],
        items: [],
        page: 1,
        itemsPerPage: 15,
        totalItems: 0
      },
      targetDataOrigin: [],
      templateDataOrigin: [],
      language: '',
      remediationDetailsModal: false,
      remediatedLightTabs: [],
      remediationReport: [],
      remediationDetails: [],
      isQrUSBRem: [Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB].includes(this.type),
      isMediaRem: this.type === Campaign.Type.RemediationMedia,
      isAdaptiveRem: this.type == Campaign.Type.RemediationAdaptive
    }
  },

  async created() {
    // define remediated tabs based on remediation type
    if (this.isAdaptiveRem) {
      this.remediatedLightTabs = [{ name: this.getTabTitle(), isActive: true, display: false }];
    } else {
      const isVisibleTargetsTab = this.$checkPermission(['phishing-view-target-risk']);
      this.remediatedLightTabs = [
        { name: this.getTabTitle(), isActive: isVisibleTargetsTab, display: isVisibleTargetsTab }, 
        { name: this.$t('Navbar.Template'), isActive: !isVisibleTargetsTab, display: true }
      ];
    }

    this.remediationDetails = [
      Campaign.Type.RemediationLight, 
      Campaign.Type.RemediationTraining, 
      Campaign.Type.RemediationQRCode, 
      Campaign.Type.RemediationUSB,
      Campaign.Type.RemediationAdaptive,
      Campaign.Type.RemediationMedia
    ];

    this.remediationReport = [
      Campaign.TypeRemediationWeak,
      Campaign.Type.RemediationWorsen,
      Campaign.Type.RemediationClickers,
      Campaign.Type.RemediationLight
    ];

    this.setOptions();

    let config = await phishingService.getConfig();
    let reportConfig = config.data.defaults.report || null;
    this.language = this.$profile.language || this.companyLan || reportConfig.lang || this.$i18n.locale;
  },

  mounted() {
    this.$eventBus.$emit('loading', true);

    Promise.all([this.getTemplate(), this.getResult()]).then((values) => {
      // Completed remediation array contains [ template_result, target_result/click_result ]
      this.template = values[0];
      this.result = values[1];

      this.prepareTemplateData(this.template);
      this.prepareResultData(this.result);
    }).catch(error => {
      console.error("RemediatedWidget - Promise all - Template & Target/Clicks - Error", error);
      this.$eventBus.$emit('show-alert', { title: this.$t('General.DataFetchError'), variant: 'danger' });
    }).finally(() => {
      this.$eventBus.$emit('loading', false);
    });
  },

  computed: {
    targets() {
      return this.data[0] || 0;
    },
    improved() {
      return this.data[1] || 0;
    },
    chartData() {
      return {
        labels: [''],
        datasets: [
          {
            data: [this.improved],
            backgroundColor: colors.green[300],
            borderColor: colors.green[100],
            borderWidth: 1
          },
          {
            data: [this.targets],
            backgroundColor: colors.primary[100],
            borderColor: colors.primary[100],
            borderWidth: 1
          }
        ]
      }
    }
  },

  methods: {
    getTabTitle() {
      return [Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB].includes(this.type)? this.$t('Dashboard.Charts.Clicks') : this.$t('Navbar.Targets'); 
    },
    async getTemplate() {
      if (this.isAdaptiveRem) return [];

      const response = await campaignService.getCompletedRemediationTemplates(this.companyId, this.remCampaignId);
      return response && response.data;
    },
    async getResult() {
      let response = null;

      if(this.isQrUSBRem) {
        response = await campaignService.getCompletedRemediationClicks(this.companyId, this.remCampaignId);
      } else if (this.isAdaptiveRem) {
        response = await reportService.getLevelAccount(this.companyId, this.remLevelId);
      } else {
        response = await campaignService.getCompletedRemediationTargets(this.companyId, this.remCampaignId);
      }
      
      return response && response.data;
    },
    prepareTemplateData(templateData) {
      let templateLabel = {
        [TemplateType.Email] : this.$t('Analytics.TemplateTypes.Email'),
        [TemplateType.SMS] : this.$t('Analytics.TemplateTypes.Sms'),
        [TemplateType.QRCode] : this.$t('Analytics.TemplateTypes.QRCode'),
        [TemplateType.USB] : this.$t('Analytics.TemplateTypes.USB'),
        [TemplateType.Media] : this.$t('Analytics.TemplateTypes.Media')
      };

      templateData.forEach((row) => {
        row.click_rate = row.sent > 0 ? (100 * (row.clicked / row.sent)).toFixed(1) + "%" : 0 + "%";
        row.used_last = row.used_last == 1 ? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');
        row.type = templateLabel[row.type] || this.$t("Analytics.NA");
      });

      this.templateDataOrigin = templateData;

      let fields = [
        { key: 'name', label: this.$t('Analytics.Table.Name') },
        { key: 'type', label: this.$t('Analytics.Type') },
      ];

      if(this.type == Campaign.Type.RemediationLight || this.isMediaRem) {
        fields.push(
          { key: 'sent', label: this.$t('Analytics.Table.Sent') },
          { key: 'clicked', label: this.$t('Analytics.Table.Clicked') },
          this.isMediaRem?
          { key: 'submitted_data', label: this.$t('Analytics.Table.Info') } :
          { key: 'click_rate', label: this.$t('Analytics.Table.ClickRate') }
        );
      } else {
        fields.push(
          { key: 'clicked', label: this.$t('Analytics.Table.Clicked') }
        );
      }

      this.template = {
        sortKey: this.sortKey,
        sortDirection: this.sortDirection,
        fields: fields,
        items: {},
        page: 1,
        totalItems: templateData.length,
        itemsPerPage: 10
      }

      this.paginateTableData(this.template, this.templateDataOrigin);
    },
    prepareResultData(resultData) {
      if(this.isQrUSBRem) {
        this.prepareClickData(resultData);
      } else if (this.isAdaptiveRem) {
        this.prepareLevelData(resultData);
      } else {
        this.prepareTargetData(resultData);
      }
    },
    prepareClickData(clickData) {
      let fields = [
        { key: 'campaign_name', label: this.$t('Analytics.Table.Campaign') },
        { key: 'rid', label: this.$t('Analytics.Table.Rid') },
        { key: 'clicked', label: this.$t('Analytics.Table.Time') },
        { key: 'phished_ip', label: this.$t('Analytics.Table.Details') }
      ];

      if(this.type == Campaign.Type.RemediationUSB) {
        fields.push(
          { key: 'machine_name', label: this.$t('Analytics.Table.Machine') },
          { key: 'machine_ip', label: this.$t('Analytics.Table.IPs') }
        );
      } else if(this.type == Campaign.Type.RemediationQRCode) {
        fields.push(
          { key: 'user_data', label: this.$t('Analytics.Table.Info') }
        );
      }

      this.targetDataOrigin = clickData;

      this.target = {
        items: {},
        fields: fields,
        sortKey: 'campaign_name',
        sortDirection: this.sortDesc,
        page: 1,
        totalItems: clickData.length,
        itemsPerPage: 10
      };

      this.paginateTableData(this.target, this.targetDataOrigin);
    },
    prepareTargetData(targetData) {
      targetData.forEach((row) => {
        row.click_rate = row.click_rate.toFixed(1) + '%';
        row.clicked_last = row.clicked_last == 1 ? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');

        if (typeof row.risk_group != 'number') {
          row.risk_group = this.$t('Analytics.Table.UnratedClickers');
        } else {
          switch (row.risk_group) {
            case 1:
              row.risk_group = this.$t('Analytics.Table.SerialClickers');
              break
            case 2:
              row.risk_group = this.$t('Analytics.Table.FrequentClickers');
              break
            case 3:
              row.risk_group = this.$t('Analytics.Table.RareClickers');
              break
            case 4:
              row.risk_group = this.$t('Analytics.Table.RareClickers');
              break
            default:
              row.risk_group = this.$t('Analytics.Table.UnratedClickers');
              break
          }
        }
      });

      this.targetDataOrigin = targetData;

      let fields = [
        { key: 'name', label: this.$t('Analytics.Table.Target') },
        { key: 'sent', label: this.$t('Analytics.Table.Sent') },
        { key: 'clicked', label: this.$t('Analytics.Table.Clicked') }
      ];

      if(this.isMediaRem) {
        fields.push({ key: 'submitted_data', label: this.$t('Analytics.Table.Info') });
      } else {
        fields.push(
          { key: 'click_rate', label: this.$t('Analytics.Table.ClickRate') },
          { key: 'risk_group', label: this.$t('Analytics.Table.RiskGroup') }
        );
      }

      fields.push({ key: 'subject', label: this.$t('Analytics.Table.LastTemplateSubject') });

      this.target = {
        items: {},
        fields: fields,
        sortKey: this.sortBy,
        sortDirection: this.sortDesc,
        page: 1,
        totalItems: targetData.length,
        itemsPerPage: 10
      };

      this.paginateTableData(this.target, this.targetDataOrigin);
    },
    prepareLevelData(levelData) {
      let fields = [
        { key: 'name', label: this.$t('Analytics.Table.Account') },
        { key: 'email', label: this.$t('Analytics.Table.Email') },
        { key: 'team', label: this.$t('Analytics.Table.Team') },
        { key: 'progress', label: this.$t('Analytics.Table.Progress') },
        { key: 'completed', label: this.$t('Analytics.Table.Completed') }
      ];

      levelData.forEach(l => { 
        l.name = l.firstname + ' ' + l.lastname;
        l.progress = Number(l.progress).toFixed(0) + ' %';
        l.completed = (Number(l.completed) == 1)? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');
      })
      this.targetDataOrigin = levelData;

      this.target = {
        items: {},
        fields: fields,
        sortKey: 'name',
        sortDirection: this.sortDesc,
        page: 1,
        totalItems: levelData.length,
        itemsPerPage: 10
      };

      this.paginateTableData(this.target, this.targetDataOrigin);
    },
    targetPageChange(page, itemsPerPage) {
      this.target.page = page;
      this.target.itemsPerPage = itemsPerPage;
      this.paginateTableData(this.target, this.targetDataOrigin);
    },
    templatePageChange(page, itemsPerPage) {
      this.template.page = page;
      this.template.itemsPerPage = itemsPerPage;
      this.paginateTableData(this.template, this.templateDataOrigin);
    },
    paginateTableData(res, origin) {
      const start = (res.itemsPerPage * res.page) - res.itemsPerPage;
      const end = res.itemsPerPage * res.page;
      res.items = [...origin].slice(start, end);
    },
    setOptions() {
      this.options = {
        layout: {
          padding: {
            left: -20,
            top: 10,
            bottom: 10
          },
        },
        scales: {
          x: {
            display: false
          },
          y: {
            stacked: true,
            grid: {
              display: false
            }
          }
        },
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2.3,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        plugins: {
          tooltips: {
            enabled: false // TODO not working
          },
          legend: {
            display: false
          }
        }
      }
    },
    downloadReport() {
      this.$eventBus.$emit('loading', true);

      let reportBody = {}, orgs = this.tags?.orgs;
      if (orgs) {
        let teamOrg, firstOrg, defaultOrg;
        for (let o in orgs) {
          const oObj = orgs[o];
          const noObj = {key: o, label: oObj.label || o};
          if (oObj.team) teamOrg = noObj
          if (oObj.order==0) firstOrg = noObj
          if (!defaultOrg) defaultOrg = noObj
        }
        reportBody = {
          ... (Object.keys(orgs).length && {reportTags: [teamOrg || firstOrg || defaultOrg]}),
          ... (this.$currentCompany.company_options?.demo_company?.value || this.$user.impersonator) && { reportCompanyId: this.$user.impersonator?.company_id || this.$currentCompany.company_id }
        }
      }

      phishingService.createRemediationReport(this.companyId, this.remCampaignId, reportBody, { lang : this.language }).then((response) => {
        this.$methods.downloadBlob(response);
      }).catch((error) => {
        console.error('RemediatedWidget - downloadReport - error', error);
      }).finally(() => {
        this.$eventBus.$emit('loading', false);
      });
    },
    exportReport() {
      this.$eventBus.$emit('loading', true);

      let promise;
      if (this.isAdaptiveRem) {
        promise = reportService.exportLevelProgress(this.companyId, this.remLevelId)
      } else {
        promise = phishingService.exportRemediationData(this.companyId, this.remCampaignId, {
          ... (this.$currentCompany.company_options?.demo_company?.value || this.$user.impersonator) && { reportCompanyId: this.$user.impersonator?.company_id || this.$currentCompany.company_id }
        })
      }

      promise.then((response) => {
        this.$methods.downloadBlob(response);
      }).catch((error) => {
        console.error('RemediatedWidget - exportReport - error', error);
      }).finally(() => {
        this.$eventBus.$emit('loading', false);
      });
    }
  },
});
</script>

<style lang="scss">
</style>