import { eventBus } from './../plugins/eventBus';
import { DateTime } from 'luxon';
import i18n from '../plugins/i18n';

const methods = {
  jsonToCsvParser: function (items) {
    // Retrieve header attributes and join them
    const header = Object.keys(items[0]);
    const headerString = header.join('|');

    // Handle null or undefined values
    const replacer = (key, value) => value ?? '';
    const rowItems = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join('|'));

    // Join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');

    return csv;
  },
  downloadBlob: function (response, filename = null, isJson = false, filenameSuffix = null) {

    if (!filename) {
      let headerLine = response.headers['content-disposition']

      // The issue is that the Content-Disposition header contains two filename fields:
      //     filename="example.csv" (non-encoded)
      //     filename*=UTF-8''example.csv (encoded according to RFC 5987)
      // When both are present, the browser prioritizes filename*.
      // We need to ensure that we extract the correct value.

      let filenameMatch = headerLine.match(/filename\*?=(?:UTF-8'')?([^;]+)/);
      if (filenameMatch) {
        // Extract the filename
        filename = decodeURIComponent(filenameMatch[1].replace(/"/g, '').trim());
      } else {
        // Extract the filename and remove quotes if present
        let startFileNameIndex = headerLine.indexOf('filename=') + 9
        filename = headerLine.substring(startFileNameIndex).trim().replace(/"/g, ''); // Remove any quotes
      }
    }
    if (filenameSuffix) {
      const lastIndex = filename.lastIndexOf('.');
      if (lastIndex !== -1) {
        filename = filename.slice(0, lastIndex) + filenameSuffix + '.' + filename.slice(lastIndex + 1);
      }
    }

    console.log(response.headers)
    let fileLink = document.createElement('a')
    if (isJson) {
      const json = this.jsonToCsvParser(response.data);
      fileLink.href = window.URL.createObjectURL(new Blob([json], { type: 'application/json' }));
    } else {
      fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
    }
    fileLink.setAttribute('download', filename)

    document.body.appendChild(fileLink)
    fileLink.click()
    URL.revokeObjectURL(fileLink.href)
  },
  fillYearLabelArray(existingArray = [], format = 'LLL yy') {
    const locale = i18n.global.locale.value;

    let len = existingArray.length

    if (len === 0)
      existingArray.push(DateTime.now().toFormat('MMyyyy')) && len++

    if (len < 12)
      for (let i = 1; i <= 12 - len; i++)
        existingArray.push(DateTime
          .fromFormat(existingArray[len - 1], 'MMyyyy')
          .plus({ month: i })
          .toFormat('MMyyyy'))
    return format !== 'LLL yy' ?
      existingArray.map((m) => {
        const date = DateTime.fromFormat(m, 'MMyyyy');
        return date.toFormat(format, { locale });
      }) :
      existingArray.map((m) => {
        const date = DateTime.fromFormat(m, 'MMyyyy');
        return date.toFormat('LLL yy', { locale });
      });
  },
  padTo(arr = [], desiredLength = 12) {
    let len = arr.length

    if (len < desiredLength)
      for (let i = 1; i <= desiredLength - len; i++)
        arr.push('')

    return arr
  },
  maxDateFromArray(array) {
    array = array.filter(e => !!e)

    return array.length > 0
      ? DateTime
        .max(...array.map(e => DateTime.fromFormat(e, 'yyyy-MM-dd HH:mm:ss')))
        .toFormat('yyyy-MM-dd HH:mm:ss')
      : null
  },
  prependNullToLength(array, desiredLength) {
    if (!array || !desiredLength || array.length >= desiredLength) return array;
    let res = array
    for (let i = 0; i < desiredLength - array.length; i++)
      res = [null, ...res]
    return res
  },
  getOrCreateConversationId() {
    let conversationId = sessionStorage.getItem('conversationId');

    if (conversationId) {
      return conversationId;
    } else {
      conversationId = crypto.randomUUID();
      sessionStorage.setItem('conversationId', conversationId);
      return conversationId;
    }
  },
  scrollToBottom(chat) {
    chat.$nextTick(() => {
      const chatBody = chat.$refs.chatBody;
      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight;
      }
    });
  },
  invokeChatAPI(fn, defaultErrorMsg, ...params) {
    return fn(...params)
      .catch(async error => {

        let errResponse = {};
        if (error.response && error.response.data instanceof Blob) {
          const text = await error.response.data.text();
          try {
            errResponse = JSON.parse(text).error || {};
          } catch (e) {
            console.error('Failed to parse error blob:', e);
            errResponse = {}; 
          }
        } else {
          errResponse = error.response?.data?.error || {};
        }
        let msg = errResponse.code ? i18n.global.tc('Error.' + errResponse.code, 0, errResponse.params || {}) : defaultErrorMsg;
        if (msg) {
          eventBus.$emit('show-alert', { title: msg, variant: 'danger' });
        }
        throw error;
      })
  }, 
  /**
   * Split the given string by the given pattern, remove the empty elements, all surrounding empty spaces and return unique and sorted values
   * @param {*} str
   * @param {*} pattern
   * @returns
   */
  splitString(str, pattern = ',') {
    return str ? [...new Set(str.split(new RegExp("\\s*" + pattern + "\\s*")).filter(d => d))].sort() : [];
  },
  /**
   * Validate if the given role is child or equal as the base role
   * @param {*} role - source role
   * @param {*} baseRole - base role
   */
  isRole(role, baseRole) {
    return role && baseRole && (role + '').startsWith(baseRole);
  },
  /**
   * Role can be base role (csm, msp) and also a custom role (csm_21, msp_3). Retrieve the base role of the given role
   * @param {*} role - custom or base role
   * @returns 
   */
  getBaseRole(role) {
    return (role + '').split('_')[0];
  },
  /**
   * Invokes an api/function that returns a promise and manage the error codes
   * @param {*} fn - api/function to invoke
   * @param {*} defaultErrorMsg - default error message to be shown
   * @param  {...any} params - api/function parameters passed as parameters of the current function (for ex: invokeFn(api, 'General error message', companyId, accountId, orgName))
   */
  invokeFn(fn, defaultErrorMsg, ...params) {
    eventBus.$emit('loading', true);
    return fn(...params)
      .catch(async error => {

        let errResponse = {};
        // Handle case where the API response is returned as a Blob instead of a regular JSON object.
        // This can happen when the backend streams the response or sets the wrong Content-Type (e.g., application/octet-stream).
        // In this case, we need to read the Blob as text and then parse it into JSON manually.
        // If the response is already a JSON object, we extract the error field directly.
        if (error.response && error.response.data instanceof Blob) {
          const text = await error.response.data.text();
          try {
            errResponse = JSON.parse(text).error || {};// Attempt to parse the text as JSON
          } catch (e) {
            console.error('Failed to parse error blob', e);
            errResponse = {}; // Fallback to empty object if parsing fails
          }
        } else {
          // Standard JSON response case
          errResponse = error.response?.data?.error || {};
        }
        let msg = errResponse.code ? i18n.global.tc('Error.' + errResponse.code, 0, errResponse.params || {}) : defaultErrorMsg;
        // show error message if there is a locale for the given code or if has been passed a default message
        if (msg) {
          eventBus.$emit('show-alert', { title: msg, variant: 'danger' });
        }
        throw error;
      })
      .finally(() => {
        eventBus.$emit('loading', false);
      })
  },

  /**
   * UrlPathname return pathname from url or empty string
   * @param {*} urlString
   */
  urlPathname(urlString) {
    try {
      return new URL(urlString).pathname;
    } catch (error) {
      return ''
    }
  },
  roundToDecimalPlaces(num, precision) {
    let multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier) / multiplier;
  },

  /**
   * capitalizeWords
   * return capitalize string
   * @param {*} str
   */
  capitalizeWords(str) {
    return str
      .split(' ') // Split the string into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join the words back into a single string
  },
  parseOrgValues(org) {
    if (!org) return null;
    let oArr = [];
    const [orgName, orgVal] = org.split(":"); // org -> text: org_name:org_value

    let oObj = {}
    oObj[orgName] = orgVal
    oArr.push(oObj);
    return oArr;
  },
  getAllOrgValues(tags, resArr) {
    for (let key in tags) {
      if (['label', 'team', 'value', 'show', 'filter'].includes(key)) continue;

      let cTag = tags[key]
      if (cTag.value) {
        cTag.value.forEach(v => {
          const newOrg = { label: key + ': ' + v, value: key + ':' + v };
          if (!resArr.some(org => org.label === newOrg.label)) {
            resArr.push(newOrg);
          }
        });

      }
      resArr = this.getAllOrgValues(tags[key], resArr);
    }
    return resArr;
  },
}
export default methods