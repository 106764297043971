<template>
  <component
    :is="tag"
    :class="{
      'table-cell': true,
      'v-separator': separator,
      'v-sortable': sortKey,
      'v-sort-asc': sortDirection === 'asc',
      'v-sort-desc': sortDirection === 'desc',
      'a-center': center,
      'a-right': right,
      'wrapped': wrapped,
    }"
    :width="width"
    @click="cellClick"
  >
    <span class="table-cell-content">
      <slot />
      <span v-if="sortKey" class="table-cell-sort-icon">
        <Icon name="chevronDownLarge" />
      </span>
    </span>
  </component>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    head: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    separator: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: undefined
    },
    sortKey: {
      type: String,
      default: undefined
    },
    wrapped: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tag() {
      return this.head ? 'th' : 'td'
    },
    sortDirection() {
      return this.$parent.sortKey === this.sortKey ? this.$parent.sortDirection : undefined
    },
  },

  methods: {
    cellClick() {
      if (!this.sortKey) return;

      const sortDirection = !this.sortDirection ? 'desc' : this.sortDirection === 'asc' ? 'desc' : 'asc'
      this.$parent.$emit('update:sortKey', this.sortKey, this.$parent.name)
      this.$parent.$emit('update:sortDirection', sortDirection, this.$parent.name)
    }
  }
});
</script>

<style lang="scss" scoped>
.table-cell {
  @apply relative py-3.5;
  @apply text-left whitespace-nowrap;

  &.a-center {
    @apply text-center;
  }

  &.a-right {
    @apply text-right;
  }

  &.v-sortable {
    @apply cursor-pointer;
  }

  &:is(th) {
    @apply font-bold text-violet-700;
  }
}

.table-cell-content {
  @apply grid gap-2 grid-flow-col-dense auto-cols-min;
  @apply mx-2.5;
  @apply whitespace-nowrap;

  .v-separator & {
    @apply border-r border-primary-300;
  }

  .a-center & {
    @apply justify-center;
  }

  .a-right & {
    @apply justify-end;
  }
  
  .wrapped & {
    @apply auto-cols-auto whitespace-normal
  }
}

.table-cell-sort-icon {
  // @apply absolute right-0 top-1/2 -translate-y-1/2;
  @apply block opacity-0;
  @apply transition-opacity duration-200 ease-in-out;

  .v-sort-asc &,
  .v-sort-desc & {
    @apply opacity-100;
  }

  .v-sort-asc & {
    .icon {
      @apply rotate-180;
    }
  }

  .table-cell:hover & {
    @apply opacity-100;
  }
}
</style>
