<template>
  <span class="markdown-body" v-html="renderedMarkdown"></span>
</template>

<script>
import { defineComponent } from 'vue'
import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkGfm from 'remark-gfm'
import remarkHtml from 'remark-html'
import 'github-markdown-css'

export default defineComponent({
  name: 'MarkdownRenderer',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    renderedMarkdown() {
      try {
        return unified().use(remarkParse).use(remarkGfm).use(remarkHtml).processSync(this.content).toString()
      } catch (e) {
        console.error('Error rendering markdown:', e)
        return this.content
      }
    },
  },
})
</script>

<style scoped lang="scss">
.markdown-body {
  @apply bg-transparent text-inherit text-base;
}
</style>
