<template>
    <div class="chatbot-wrapper">
      <div v-if="!isChatOpen" class="icon-wrapper" @click="toggleChat">
        <Icon name="chatBot" :size="30" />
      </div>
      <div v-if="isChatOpen" class="chat-box">
        <div class="chat-header">
          <span>{{ $t('ChatBot.CyberAdvisor') }}</span>
          <button class="close-btn" @click="toggleChat">&times;</button>
        </div>
        <div ref="chatBody" class="chat-body">
          <div v-for="(msg, index) in messages" :key="index" :class="['chat-message', msg.type]">
            <span v-if="msg.type === messageType.sent">{{ msg.text }}</span>
            <MarkdownRender v-else :content="msg.text" />
          </div>
          <div v-if="typingIndicator" class="typing-indicator">
            <em>{{ $t('ChatBot.Typing') }}</em>
          </div>
        </div>
        <div class="chat-footer">
          <CGInput  v-model="newMessage" class="chat-input" :placeholder="$t('ChatBot.InputPlaceholder')" @keyup.enter="sendMessage" />
          <CGButton :disabled="typingIndicator" class="send-btn" @click="sendMessage">{{ $t('ChatBot.SendMessage') }}</CGButton>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { defineComponent } from 'vue';
  import { messageType } from '@/common/constants';

  import Icon from '../atoms/Icon.vue';
  import llmService from '@/services/llm.service.js';
  
  export default defineComponent({
    components: { Icon },
  
    data() {
      return {
        isChatOpen: false,
        newMessage: '',
        conversationId: '',
        messageType: messageType,
        typingIndicator: false,
        account_name: this.$profile.firstname || this.$profile.lastname || this.$profile.username,
        language: this.$profile.language ?? this.$company.default_language,
        messages: [],
      };
    },
    created() {
      this.conversationId = this.$methods.getOrCreateConversationId()
    },
    mounted() {
      this.newMessage = messageType.welcome
      this.sendMessage();
    },
    methods: {
      toggleChat() {
        this.isChatOpen = !this.isChatOpen;
        this.$nextTick(() => this.$methods.scrollToBottom(this));
      },

      async sendMessage() {
        if (this.newMessage.trim() !== '') {
          const typeMessage = { type: messageType.sent, text: this.newMessage };
          if (this.messages.length) this.messages.push(typeMessage);
          this.newMessage = '';
          this.$methods.scrollToBottom(this);
          this.typingIndicator = true;

          await this.$methods.invokeChatAPI(llmService.sendMessage, this.$t('ChatBot.SendMessageError'), { id: this.conversationId, language: this.language, message: typeMessage.text }, { headers: { 'x-account-name': this.account_name } })
            .then(response => {
              if (response?.data?.message) {
                this.messages.push({ type: messageType.received, text: response.data.message });
                this.$methods.scrollToBottom(this);
              }
            })
            .catch(error => {
              console.error('Error sending message: ', error);
            })
          this.typingIndicator = false;
        };
      }, 
    },
  });
</script>
  
<style lang="scss" scoped>
  .chatbot-wrapper {
    @apply fixed bottom-5 right-5 flex items-center justify-center z-50;
  }
  
  .icon-wrapper {
    @apply h-19 w-19 flex items-center justify-center bg-white p-4 rounded-full hover:shadow-lg cursor-pointer border;
  }
  
  .chat-box {
    @apply w-80 bg-white shadow-lg rounded-lg overflow-hidden;
  }
  
  .chat-header {
    @apply bg-[#1f1584] text-white p-3 flex justify-between items-center;
  }
  
  .close-btn {
    @apply text-white text-xl cursor-pointer;
  }
  
  .chat-body {
    @apply p-3 h-60 overflow-y-auto flex flex-col space-y-2 bg-gray-100;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }
  
  .chat-body::-webkit-scrollbar {
    width: 6px;
  }
  
  .chat-body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  .chat-message {
    @apply p-2 rounded-md max-w-[75%];
  }
  
  .received {
    @apply bg-gray-300 text-black self-start;
  }
  
  .sent {
    @apply bg-[#1f1584] text-white self-end;
  }

  .typing-indicator {
    @apply text-gray-500 italic mt-2 self-start animate-pulse;
  }
  
  .chat-footer {
    @apply p-2 flex items-center border-t;
  }
  
  .chat-input {
    @apply flex-1 p-2 rounded-md outline-none;
  }
  
  .send-btn {
    @apply bg-custom-primary text-white px-3 py-2 ml-2 rounded-md;
  }
</style>  